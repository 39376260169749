.cards {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  // flex-wrap: wrap;
  row-gap: 2rem;
}

.card-wrapper {
  height: 450px;
  display: flex;
  justify-content: center;

  text-decoration: none;
  color: inherit;
}

@media only screen and (max-width: 600px) {
  .card-wrapper {
    height: 30rem;
  }
}

@media only screen and (max-width: 1900px) {
  .cards {
    justify-content: center;
    gap: 3rem;
  }
}

@media only screen and (max-width: 1300px) {
  .cards {
    flex-wrap: wrap;
  }
}

.slider {
  display: block;
}

@media only screen and (max-width: 1200px) {
  .cards {
    display: none;
  }
}

.grid_dot {
  height: 10px;
  width: 10px;
  background: #ccc;
  border-radius: 50%;
  position: relative;
}

.slice_slide {
  :global(.slick-list) {
    padding: 2rem 0;
  }
  :global(.slick-track) {
    border: 1px solid red;
    padding: 2rem 0;
  }
}

:global(.slick-track) {
  padding: 2rem 0;
}

:global(.slick-dots) {
  li {
    cursor: pointer;
    border-radius: 50%;
    display: flex !important;
    align-items: center;
    justify-content: center;
    margin: 0;
  }
  :global(.slick-active) {
    & > div {
      background-color: var(--teal-color);
    }
  }
}
