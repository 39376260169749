@import "./fonts";

:root {
  --teal-color: #0594a9;
  --pink-color: #ff8b8b;
}
$teal-color: var(--teal-color);
$pink-color: var(--pink-color);

.container {
  padding-left: 15px;
  padding-right: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px) {
  .container {
    padding: 0 15px;
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px !important;
  }
}

@media (min-width: 1300px) {
  .container {
    width: 1300px !important;
  }
}

@media (min-width: 1900px) {
  .container {
    width: 1850px !important;
  }
}

ul {
  list-style: none;
  padding: 0;
}

:root {
  font-size: 16px;
}

@media only screen and (max-width: 900px) {
  :root {
    font-size: 14px;
  }
}
@media only screen and (max-width: 600px) {
  :root {
    font-size: 12px;
  }
}
