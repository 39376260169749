.footer {
  padding-top: 20rem;
  color: #fff;
  background-image: url(../../../public/assets/footer-bg-1.png);
  position: relative;

  & h2 {
    color: #fff !important;
  }

  & > div:nth-child(2) {
    //   display: flex;
    //   justify-content: space-between;
    //   flex-wrap: wrap;

    //   & > div {
    //     flex: 0 0 40%;
    //     overflow: hidden;
    //   }

    //   & > div:first-child {
    //     flex: 0 0 25%;
    //   }

    //   & > div:last-child {
    //     flex: 0 0 25%;
    //   }
  }
}

@media only screen and (max-width: 1200px) {
  .footer {
    & > div:nth-child(2) {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      & > div {
        flex: 0 1 auto;
      }

      & > div:first-child {
        order: 2;
        flex: 0 0 45%;
      }

      & > div:last-child {
        flex: 0 0 45%;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .footer {
    background-repeat: no-repeat;
    background-size: cover;

    & > div:nth-child(2) {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      & > div {
        flex: 0 1 auto;
      }

      & > div:first-child {
        order: 2;
        flex: 0 0 100%;
      }

      & > div:last-child {
        flex: 0 0 100%;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .footer {
    // padding-top: 21rem !important;
    background-position: center center;
  }
}
