:root {
  --teal: #0594a9;
}

/* 
bar colors
fdc063
05c3cc
ff8b8b
#ff904c
*/

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: red; */
  background-color: #efefef !important;
  /* color: rgb(255, 144, 76); */
  box-sizing: border-box !important;
  -webkit-box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* 
background: #FC5C7D;
background: -webkit-linear-gradient(to right, #6A82FB, #FC5C7D);  
background: linear-gradient(to right, #6A82FB, #FC5C7D); 
*/

/* UTILITES */
.section-title {
  font-size: 2.4rem !important;
  font-weight: 300;
  text-align: center;
  margin: 1rem 0 4rem !important;
  text-transform: uppercase;
  letter-spacing: 1rem;
  letter-spacing: 6px !important;
  color: #555 !important ;
}

@media only screen and (max-width: 900px) {
  .section-title {
    font-size: 2rem !important;
  }
}

@media only screen and (max-width: 600px) {
  .section-title {
    font-size: 1.6rem !important;
  }
}

/* @media only screen and (max-width: 400px) {
  .section-title {
    font-size: 1.2rem !important;
  }
} */

.section-title:after {
  display: block;
  height: 2px;
  background-color: var(--teal);
  content: " ";
  width: 100px;
  margin: 0 auto;
  margin-top: 30px;
}

.justify {
  text-align: justify;
}

.sub-header {
  font-size: 1.4rem !important ;
  color: #555;
  text-transform: uppercase;
  margin-bottom: 1.5rem !important;
  font-weight: 100 !important;
  /* text-align: center; */
}

.container {
  max-width: 1920px;
  margin: 0 auto;
  padding: 0 1rem;
}

/* APPBAR */
.appbar {
  width: 40rem;
  /* border: 1px solid red; */
}

.MuiToolbar-root {
  width: 80rem;
  /* flex-direction: column; */
}

.list-items {
  list-style: none;
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0 auto;
}

.list-item {
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 1px;
  cursor: pointer;
  padding: 2rem 1rem;
  font-size: 0.9rem !important;
  font-weight: 400;
}

.list-item:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.list-item.active {
  background-color: rgba(255, 255, 255, 0.2);
}

.list-link {
  text-decoration: none;
  color: #fff;
}

.school-logo-box {
  flex-grow: 18;
  /* border: 1px solid red; */
}

.school-logo {
  height: 5rem;
}

.scube-logo {
  height: 5rem;
}

.silk-text {
  font-family: "Bubblegum Sans", "cursive" !important;
  color: #d4af37;
  font-size: 3rem !important;
}

@media only screen and (min-width: 1576px) {
  .MuiToolbar-root {
    width: 117rem !important;
  }

  .MuiToolbar-root .scube-box {
    flex-grow: 5;
  }

  .MuiToolbar-root .silk-box {
    flex-grow: 5;
  }

  .MuiToolbar-root .nav-box .list-items {
    flex-grow: 0;
    /* justify-content: flex-start; */
    /* justify-content: flex-start; */
    /* border: 1px solid white; */
    margin: 0;
    margin-right: 5rem;
  }

  .MuiToolbar-root .school-logo-box {
    /* flex-grow: 10; */
  }

  .list-item {
    padding: 3rem 1rem;
  }

  .school-logo {
    height: 8rem;
  }

  .scube-logo {
    height: 8rem;
  }

  .silk-text {
    font-size: 6rem !important;
    line-height: 3rem !important;
    /* padding: 2rem 0; */
  }

  .list-item {
    font-size: 1.4rem !important;
  }
}

/* STORY */
.story {
  background-color: #fff;
  padding: 4rem 0;
  color: #555;
  /* color: #fff; */
  position: relative;
  overflow: hidden;
}

.bg-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  /* opacity: 0.15; */
  background-color: rgba(0, 0, 0, 0.9);
  overflow: hidden;
}

.video {
  height: 100%;
  width: 100;
  object-fit: cover;
  opacity: 0.5;
}

.story .arrow-img {
  position: absolute;
  top: 20%;
  left: 63%;
}

.story .img {
  text-align: center;
}

/* STUDENTS */
.students {
  padding: 4rem 0 20rem;
  background-color: #fff;
  position: relative;
  overflow: hidden;
}

.students .garden {
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 0 auto;
}
.students .garden-girl {
  position: absolute;
  bottom: 11%;
  left: 6%;
  height: 12rem;
  margin: 0 auto;
}

.students .garden-boy {
  position: absolute;
  bottom: 9%;
  right: 5%;
  height: 12rem;
  margin: 0 auto;
}

.students .swiper {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}

.students .swiper-slide {
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  row-gap: 0.7rem;
}

.students .img-box {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24%;
  overflow: hidden;
  position: relative;
  height: 14rem;
  background-color: #000;
}

.students .swiper-slide img {
  min-width: 100%;
  min-height: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.8;
  transition: all 0.3s;
}

.students .swiper-slide img:hover {
  opacity: 1;
  /* transform: scale(1.1); */
  transition: all 0.3s;
  cursor: pointer;
}

.students .img-box .img-content {
  position: absolute;
  color: #fff;
  top: 70%;
  left: 50%;
  opacity: 1;
  transform: translateX(-50%);
  width: 80%;
  text-align: center;
  transition: all 0.5s;
}

.students .img-box:hover .img-content {
  display: none;
}

.students .message {
  height: 90%;
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  align-items: center;
  /* justify-content: center; */
  text-align: justify;
  color: #555;
}

.students .message-title {
  padding: 0 1rem 0.5rem;
  font-size: 1.3rem;
  text-transform: uppercase;
}

.teachers .swiper-slide {
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: #000;
  border-radius: 5px;
}

.teachers .swiper-slide img {
  opacity: 0.8;
  transition: all 0.3s;
}

.teachers .swiper-slide img:hover {
  opacity: 1;
  transform: scale(1.1);
  cursor: pointer;
}

.teachers .swiper-slide:hover .img-content {
  display: none;
}

.teachers .img-content {
  position: absolute;
  color: #fff;
  top: 70%;
  left: 50%;
  opacity: 1;
  transform: translateX(-50%);
  width: 80%;
  text-align: center;
  transition: all 0.3s;
}

.t863efg7990----.css-19kzrtu {
  /* background: #fc5c7d;
  background: -webkit-linear-gradient(to right, #6a82fb, #fc5c7d);
  background: linear-gradient(to right, #6a82fb, #fc5c7d); */
  /* background-color: #ff8b8b; */
  /* color: #000; */
  /* color: white; */
  /* color: white; */
  /* border-radius: 0 0 5px 5px; */
}

.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected.css-t1sord-MuiButtonBase-root-MuiTab-root[tabindex="0"] {
  /* background-color: #ff8b8b; */
  /* color: #000;
  font-weight: 600; */
}

/*  */
.CircularProgressbar .CircularProgressbar-text {
  font-weight: 700;
  transform: translate(2px, 4px);
  font-size: 2rem !important;
}

/* .css-1kesrjl-MuiInputBase-root-MuiOutlinedInput-root.Mui-error
  .MuiOutlinedInput-notchedOutline {
  border-color: #fff !important;
}

.css-1g43vp6-MuiInputBase-root-MuiOutlinedInput-root.Mui-error
  .MuiOutlinedInput-notchedOutline {
  border-color: #fff !important;
}

.css-i4bv87-MuiSvgIcon-root {
  fill: #fff !important;
} */

/* .css-1wc848c-MuiFormHelperText-root.Mui-error {
  color: #fbb13c !important;
  font-weight: 600;
} */
