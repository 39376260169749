.contact {
  padding: 1rem 0;

  & .input {
    & svg {
      fill: #fff !important;
    }

    & > label {
      color: white;
    }
    & > div:last-child {
      & > fieldset {
        border-color: white;
      }
    }

    & p {
      color: lighten(#f00, 30%) !important;
      font-weight: 600;
    }
  }

  & .formControl {
    width: 100%;
  }
}

:global(.MuiInputBase-input) {
  color: white !important;
}

@media only screen and (max-width: 1200px) {
  .contact {
    padding-bottom: 4rem;
  }
}
