@import "/src/index.scss";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap");

.card {
  position: relative;
  height: 25rem;
  width: 25rem;
  background-color: #fff;
  padding: 1rem 1rem 2rem;
  border-radius: 0.5rem;
  transition: all 0.3s;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > div:first-child {
    & > svg {
      font-size: 3.4rem;
      // stroke: #fff;
      fill: $teal-color;
    }
  }

  & h3 {
    font-weight: 400;
    text-transform: uppercase;
    font-size: 1.4rem;
    text-align: center;
    color: black;
    // font-family: "Outfit", "Robot";
    // font-weight: 700;
    font-size: 1.2rem;
  }

  & p {
    text-align: justify;
    // font-family: "Outfit", "Robot";
    // font-weight: 500;
    // color: white;
  }

  & > a {
    margin-top: auto;
    text-decoration: none;
    color: white;
    // border: 1px solid black;
    padding: 0.5rem 1rem;
    border-radius: 1rem;
    background-color: $teal-color;

    display: flex;
    align-items: center;
    gap: 0.5rem;
    transition: gap 300ms;

    &:hover {
      background-color: white;
      color: $teal-color;
      gap: 2rem;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

      transition: gap 300ms;
    }
  }
}

@media only screen and (max-width: 1000px) {
  .card {
    // border: 1px solid black;
    height: 28rem;
    width: 20rem;
  }
}
