.bars {
  & ul {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    justify-items: center;

    & > li {
    }
  }
}

@media only screen and (max-width: 1900px) {
  .bars {
    & > h2 {
      text-align: center;
    }

    & > ul {
      justify-content: center;
      gap: 3rem;
      & > li {
        flex: 0 1 auto;
      }
    }
  }
}

@media only screen and (max-width: 1377px) {
  .bars {
    h2 {
      font-size: 2rem !important;
    }
    & > ul {
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(1, 1fr);
    }
  }
}

@media only screen and (max-width: 1000px) {
  .bars {
    h2 {
      font-size: 2rem !important;
    }
    & > ul {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      // flex-direction: column;
      // align-items: center;
    }
  }
}

@media only screen and (max-width: 600px) {
  .bars {
    h2 {
      font-size: 1.4rem !important;
    }
    & > ul {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      // flex-direction: column;
      // align-items: center;
    }
  }
}

@media only screen and (max-width: 400px) {
  .bars {
    h2 {
      font-size: 1rem !important;
    }
    & > ul {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      // flex-direction: column;
      // align-items: center;
    }
  }
}
