.students {
  position: relative;
  // background-color: #fff;
  // height: 60rem;
  overflow: hidden;

  & .students-box {
    padding: 4rem 0 !important;
    color: #555;
    overflow: hidden;

    & > h2 {
      font-size: 2.4rem;
      font-weight: 300;
      text-align: center;
      margin: 1rem 0 4rem;
      text-transform: uppercase;
      letter-spacing: 1rem;
      letter-spacing: 6px;
      color: #555;

      &:after {
        display: block;
        height: 2px;
        background-color: var(--teal);
        content: "";
        width: 100px;
        margin: 0 auto;
        margin-top: 30px;
      }
    }

    & .students-body {
      display: flex;
      justify-content: space-between;

      & > div:first-child {
        flex: 0 1 65%;
        overflow: hidden;
      }

      & > div:last-child {
        flex: 0 1 30%;
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .students {
    // height: 80rem;
    padding: 0 2rem;

    & .garden {
      height: 8rem;
    }

    & .boy {
      height: 8rem;
      bottom: 5%;
    }

    & .girl {
      height: 8rem;
      bottom: 7%;
    }

    & .students-body {
      flex-direction: column;
      justify-content: center;
      gap: 4rem;
    }
  }
}
