.img-box {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  height: 25rem;

  & .row {
    width: 100%;
    height: 100%;
    display: flex;
    gap: 0.5rem;
  }
}

@media screen and (min-width: 300px) {
  :global(.swiper-container) {
    width: 300px;
  }
}

@media screen and (min-width: 600px) {
  :global(.swiper-container) {
    width: 600px;
  }
}

@media screen and (min-width: 900px) {
  :global(.swiper-container) {
    width: 920px;
  }
}
