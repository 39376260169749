.schoolInfo {
  & .img-box {
    text-align: center;

    & img {
      width: 50%;
    }
  }

  &__list {
    & > li {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .schoolInfo {
    & .img-box {
      & img {
        height: 10rem;
        width: auto;
      }
    }
  }
}
