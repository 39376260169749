/* COTACT */
.contact {
  padding: 20rem 0 0;
  /* margin-top: 3rem; */
  /* background-color: #333; */
  color: #fff;
  background-image: url(../../../public/assets/footer-bg-1.png);
  position: relative;
  overflow: hidden;
}

.contact .section-title {
  color: #fff;
  margin: 1rem 0 2rem !important;
}

.contact .MuiSvgIcon-root {
  color: #fff;
  stroke: #555;
}

.contact .cloud-img {
  position: absolute;
  top: 50%;
  left: 18%;
  height: 3rem;
  padding: 0;
}

.contact .cloud-img-1 {
  position: absolute;
  top: 50%;
  left: 60%;
  height: 3rem;
  padding: 0;
}

.contact .sun-img {
  position: absolute;
  top: 42%;
  left: 70%;
  height: 8rem;
  padding: 0;
}

.contact .pencil-boy {
  position: absolute;
  top: 68%;
  left: 75%;
  height: 6rem;
  padding: 0;
  background-color: transparent;
}

.contact .pencil-girl {
  position: absolute;
  /* top: 90%; */
  bottom: 6.5%;
  left: 20%;
  height: 6rem;
  padding: 0;
}

.contact .grid {
  align-items: flex-end;
}

.contact .contact-scube {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.contact .contact-scube img {
  align-self: center;
  width: 9rem;
}

.contact .contact-scube .scube-info {
  margin-top: 1rem;
}

.contact .scube-info .MuiSvgIcon-root {
  font-size: 1.4rem;
  margin-right: 0.3rem;
  /* fill: #555; */
  stroke: #555;
}

.contact .scube-info p {
  /* text-transform: uppercase; */
}

.contact .form-control {
  width: 100%;
  /* color: inherit; */
}

.contact .MuiTextField-root {
  /* color: inherit !important; */
}

.contact .MuiFormLabel-root {
  color: white !important;
}

.contact .MuiOutlinedInput-notchedOutline {
  border-color: white !important;
}

.contact .MuiInputBase-root {
  color: white;
}

.contact .MuiTextField-root::before {
  border-color: #fff;
}

.contact form .MuiSvgIcon-root {
  font-size: 2rem;
  stroke: #555;
}

.contact
  .MuiFormHelperText-root.Mui-error.MuiFormHelperText-sizeMedium.MuiFormHelperText-contained {
  color: #ff726f;
  font-weight: bold;
}

.contact .list {
  list-style-type: none;
  padding: 0;
}

.contact .list li {
  display: flex;
  align-items: center;
  margin: 1rem 0 0;
}

.contact .list li .MuiSvgIcon-root {
  margin-right: 0.3rem;
}

.contact .school-info {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 1rem;
  /* border: 1px solid white; */
}

.contact .school-info img {
  width: 9.5rem;
  align-self: center;
  transform: translateX(-1rem);
}

.contact .copyright {
  border-top: 2px solid white;
  padding: 1rem;
  margin-top: 2rem;
  text-align: center;
}
