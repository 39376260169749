@import '/src/index.scss';

.services {
  padding: 4rem 0 !important;
  color: #555;
  overflow: hidden;

  &__body {
    display: flex;
    flex-direction: column;
    gap: 4rem;

    & > div:first-child {
      flex: 0 0 65%;
      // width: 70%;

      & .wrapper {
        display: flex;
        gap: 1rem;
        flex-wrap: wrap;
      }

      & .slider {
        display: none;
      }
    }

    & .sidebar {
      display: flex;
      justify-content: center;

      & h2 {
        font-size: 1.3rem;
        text-transform: uppercase;
        margin: 0 0 0.5rem;
        font-weight: 400;
      }

      & .events {
        flex: 0 1 50%;
        // margin-top: 1.5rem;
      }

      & ul {
        & li {
          display: flex;
          align-items: center;
          margin: 1rem 0;

          & svg {
            height: 1.25rem;
            width: 1.25rem;
            color: #fff;
            background-color: $teal-color;
            border-radius: 5px;
            margin-right: 0.5rem;
            font-size: 1.2rem;
            padding: 0.2rem;
            -webkit-transition: background-image 2s ease-in-out;
            transition: background-image 2s ease-in-out;

            &:hover {
              background-image: linear-gradient(
                180deg,
                rgb(255, 186, 17) 11%,
                rgb(255, 168, 11) 49%,
                rgb(255, 151, 6) 100%
              );
              -webkit-transition: background-image 2s ease-in-out;
              transition: background-image 2s ease-in-out;
            }
          }
        }
      }
    }
  }
}

// @media only screen and (max-width: 1378px) {
//   .services {
//     &__body {
//       & .cards {
//         gap: 1.2rem;
//         row-gap: 1.2rem;

//         & li {
//           width: 16rem;
//         }
//       }
//     }
//   }
// }

@media only screen and (max-width: 1200px) {
  .services {
    &__body {
      flex-direction: column;
      gap: 8rem;

      & .sidebar {
        flex-direction: row;
        justify-content: space-evenly;

        & .events {
          flex: 0 1 auto;
          margin-top: 0;
        }

        & ul {
          margin: 0;
        }
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .services {
    &__body {
      & > div:first-child {
        & .slider {
          display: block;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .services {
    & .events {
      width: 20rem;
    }

    & .news {
      width: 20rem;
    }

    & .sidebar {
      flex-direction: column;
      gap: 2rem;
      align-items: center;
      margin: 0 3rem;
    }
  }
}

// .grid_dot {
//   height: 10px;
//   width: 10px;
//   background: #ccc;
//   border-radius: 1rem;
//   position: relative;
// }

// .slice_slide {
//   :global(.slick-list) {
//     padding: 2rem 0;
//     // overflow: hidden;
//     // @include customMedia(0, 991px) {
//     //   padding: 0 !important;
//     // }
//   }
//   :global(.slick-track) {
//     border: 1px solid red;
//     padding: 2rem 0;
//     // @include customMedia(0, 991px) {
//     // width: auto !important;
//     // }
//     // > div {
//     //   padding: 0.5rem;
//     // }
//   }
//   // @include smMedia("full") {
//   //   :global(.slick-dots) {
//   //     justify-content: center !important;
//   //   }
// }

// :global(.slick-track) {
//   padding: 2rem 0;
// }
// :global(.slick-list) {
//   width: 66%;
//   border: 1px solid red;
// }

// :global(.slick-dots) {
//   li {
//     cursor: pointer;
//     border-radius: 50%;
//     display: flex !important;
//     align-items: center;
//     justify-content: center;
//     margin: 0;
//   }
//   :global(.slick-active) {
//     position: relative;
//     padding: 0.25rem;
//     background-color: rgb(32, 167, 172);
//     width: 27px;
//     height: 27px;
//     & > div {
//       // background: rgb(32, 167, 172);
//       position: absolute;
//       top: 50%;
//       left: 50%;
//       transform: translate(-50%, -50%);
//     }
//   }
// }

// :global(.slick-dots) {
//   border: 1px solid black;
//   display: flex !important;
//   align-items: center;

//   & > .slick-active {
//     background-color: red;

//     & > div {
//       background: rgb(32, 167, 172);
//       position: absolute;
//       top: 50%;
//       left: 50%;
//       transform: translate(-50%, -50%);
//     }
//   }

//   & > li {
//     width: 1rem;
//     height: 1rem;

//     background-color: red;
//   }
// }

.grid_dot {
  height: 10px;
  width: 10px;
  background: #ccc;
  border-radius: 1rem;
  position: relative;
}

:global(.slick-slide) {
  // height: 30rem !important ;
  // margin: 0 10px;

  // & > div {
  //   display: flex;
  // }
}

.slick-slide {
  // margin: 0 10px;
}

:global(.slick-list) {
  // margin: 0 -10px;
}
