.wrapper {
  // display: flex;
  // gap: 1rem;
  // flex-wrap: wrap;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  // row-gap: 2rem;
  gap: 2rem;
  justify-content: center;
}

// @media only screen and (max-width: 1200px) {
@media only screen and (max-width: 1000px) {
  .wrapper {
    display: none;
  }
}
