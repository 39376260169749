.drawer {
  height: 100vh;
  width: 30vw;
  overflow: hidden;
  // background-color: #fff;
  background-image: linear-gradient(
    135deg,
    rgb(32, 167, 172) 11%,
    rgb(33, 138, 174) 49%,
    rgb(24, 42, 115) 100%
  );
  position: fixed;
  top: 0;
  left: 0;
  z-index: 500;
  transform: translateX(-100%);
  transition: all 0.3s;

  padding: 2rem 0;

  & .close-btn-box {
    display: flex;
    justify-content: flex-end;
    margin-right: 0.5rem;

    & > button {
      border: none;
      background-color: transparent;
      cursor: pointer;

      & > svg {
        width: 1.4rem;
        height: 1.4rem;
        fill: #fff;
      }
    }
  }

  & > ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    font-size: 1.4rem;
    padding: 0;

    & > li {
      & > a {
        text-decoration: none;
        color: #fff;
        display: block;
        padding: 0.5rem 1rem;
        text-transform: uppercase;
        &:hover {
          background-color: rgba(255, 255, 255, 0.2);
        }
      }
    }
  }
}

.backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.3);
}

@media only screen and (max-width: 600px) {
  .drawer {
    width: 50vw;
  }
}

@media only screen and (max-width: 400px) {
  .drawer {
    width: 70vw;
  }
}
