.progressbar {
  // display: flex;

  & .circular {
    width: 8rem;
  }

  & .progressbarTitle {
    color: #333;
    display: flex;
    gap: 0.3rem;
    align-items: center;
  }

  & .improvementBullet {
    height: 1rem;
    width: 1rem;
    border-radius: 3px;
    transform: translateY(-2px);
  }
}

@media only screen and (max-width: 600px) {
  .progressbar {
    & .circular {
      width: 6rem;
      margin: 0 auto;
    }

    & .progressbarTitle {
      font-size: 0.85rem;
    }
  }
}

@media only screen and (max-width: 400px) {
  .progressbar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & .circular {
      width: 4rem;
    }

    & .progressbarTitle {
      font-size: 0.7rem;
    }
  }
}
