@import "/src/index.scss";

.why-scube {
  padding: 4rem 15px !important;
  color: #555;
  overflow: hidden;

  & > h2 {
    text-align: center;
    font-size: 3rem;
    font-weight: 700;
    font-family: "Outfit", "Roboto";
    margin-top: 0;
    color: black;

    & > span {
      color: $teal-color;
    }
  }

  &__content {
    display: flex;
    justify-content: space-between;
    // align-items: center;

    & > div:first-child {
      flex: 0 1 65%;
    }

    & > div:last-child {
      flex: 0 1 30%;
    }
  }
}

@media only screen and (max-width: 1900px) {
  .why-scube {
    &__content {
      flex-direction: column;
      gap: 8rem;

      & > div:first-child,
      & > div:last-child {
        flex: 0 1 auto;
      }

      & > div:first-child {
      }
    }
  }
}
